import { css } from '@emotion/css';
import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  getWorkshop,
  deleteWorkshop,
  patchWorkshop,
  extendWorkshop,
  sendWorkshopEmails,
  testWorkshop,
  cancelWorkshopJob,
} from 'services/workshopAPI';
import { Workshop } from 'types/workshop';
import {
  Button,
  ConfirmModal,
  Modal,
  HorizontalGroup,
  VerticalGroup,
  Icon,
  IconButton,
  LoadingPlaceholder,
  Alert,
  ToolbarButton,
  ToolbarButtonRow,
  Menu,
  Dropdown,
  Checkbox,
  InlineField,
  Input,
  JSONFormatter,
  Tab,
  TabsBar,
  TabContent,
  Stack,
  useStyles2,
  Tooltip,
  Card,
} from '@grafana/ui';
import { AppEvents, GrafanaTheme2 } from '@grafana/data';
import { prefixRoute, ROUTES } from 'utils/routing.util';
import WorkshopAttendeeDetails from 'components/WorkshopAttendeeDetails/WorkshopAttendeeDetails';
import DeployableDemoHeader from 'components/DeployableDemoHeader/DeployableDemoHeader';
import DeployableDemoUserDetails from 'components/DeployableDemoUserDetails/DeployableDemoUserDetails';
import { isWithin48Hours, humanExpiryDate, replaceVariables } from 'utils/workshop.util';
import { PluginPage, getAppEvents } from '@grafana/runtime';
import { AuthenticatedUserContext } from 'utils/usercontext.util';
import { hasPermission } from 'utils/auth.util';
import { EnvironmentStatusBadge } from 'components/EnvironmentStatusBadge';

interface RouteParams {
  id: string;
}

const WorkshopDocumentation: React.FC<{
  outputs: Record<string, any> | undefined;
  readme?: string;
}> = ({ outputs, readme }) => {

  return (
    <Stack gap={2} direction={'column'}>
      <>
        {readme && (
          <Card style={{ maxWidth: '600px' }}>
          <div>
            <h3>Documentation</h3>
            <p>This environment has the following documentation:</p>
            <pre>
              {replaceVariables(readme, outputs)}
            </pre>
          </div>
          </Card>
        )}
        <Card style={{ maxWidth: '600px' }}>
          <div>
            <h3>Environment properties</h3>
            <p>This environment has the following properties:</p>
            <JSONFormatter json={outputs || {}} open={1} />
          </div>
        </Card>
      </>
    </Stack>
  );
};

type ActiveTab = 'details' | 'users';

const WorkshopDetails: React.FC = () => {
  const styles = useStyles2(getStyles);
  const [workshop, setWorkshop] = useState<Workshop | null>(null);
  const { id } = useParams<RouteParams>();
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRebuildModal, setShowRebuildModal] = useState(false);
  const [showUnlinkModal, setShowUnlinkModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showTestModal, setShowTestModal] = useState(false);
  const [showCancelJobModal, setShowCancelJobModal] = useState(false);
  const [showForceDeleteModal, setShowForceDeleteModal] = useState(false);
  const [showExtendModal, setShowExtendModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showExtraConfirmModal, setShowExtraConfirmModal] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [isForceUnlock, setIsForceUnlock] = useState(false);
  const [extendHours, setExtendHours] = useState(24);
  const [isDeployableDemo, setIsDeployableDemo] = useState(false);
  const [activeTab, setActiveTab] = useState<ActiveTab>('users');
  const authenticatedUser = useContext(AuthenticatedUserContext);
  const appEvents = getAppEvents();

  // Fetch workshop data on component mount
  useEffect(() => {
    (async () => {
      try {
        const data = await getWorkshop(id);

        setWorkshop(data);
        setIsDeployableDemo(data.type.startsWith('deployabledemo'));

        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching workshop details:', error);
        setIsLoading(false);
      }
    })();
  }, [id, refreshKey]);

  const copyToClipboard = async (input: any, type: string) => {
    let text = '';

    if (type === 'text') {
      text = input;
    }

    try {
      await navigator.clipboard.writeText(text);
      appEvents.publish({
        type: AppEvents.alertSuccess.name,
        payload: ['Copied to clipboard'],
      });
    } catch (err) {
      appEvents.publish({
        type: AppEvents.alertWarning.name,
        payload: ['Failed to copy text to clipboard.'],
      });
    }
  };


  const handleRebuild = async () => {
    setShowRebuildModal(false);

    try {
      await patchWorkshop({ id, forceUnlock: isForceUnlock });
      appEvents.publish({
        type: AppEvents.alertSuccess.name,
        payload: ['Workshop rebuild requested. Please allow up to 2 hours to complete.'],
      });
      setTimeout(() => {
        setRefreshKey(refreshKey + 1);
      }, 1000);
    } catch (error) {
      console.error('Error requesting rebuild of workshop:', error);
    }
  };

  const handleExtend = async () => {
    try {
      await extendWorkshop({ id, hours: extendHours });
      setShowExtendModal(false);
    } catch (error) {
      console.error('Error extending workshop:', error);
    }
  };

  const handleUnlink = async () => {
    setShowUnlinkModal(false);

    try {
      await deleteWorkshop({ id: id, ignoreDate: true, dbOnly: true });
      history.push(prefixRoute(ROUTES.Environments));
    } catch (error) {
      console.error('Error unlinking workshop:', error);
    }
  };

  const handleEmail = async () => {
    setShowEmailModal(false);

    try {
      await sendWorkshopEmails(id);
    } catch (error) {
      console.error('Error sending email to all users:', error);
    }
  };

  const handleTest = async () => {
    setShowTestModal(false);

    try {
      await testWorkshop(id);
    } catch (error) {
      console.error('Error scheduling tests:', error);
    }
  };

  const handleCancelJob = async () => {
    if (!workshop?.jobId) {
      return;
    }
    setShowCancelJobModal(false);

    try {
      await cancelWorkshopJob(id, workshop.jobId);
    } catch (error) {
      console.error('Error cancelling job:', error);
    }
  };

  const handleForceUnlockChange = (e: any) => {
    setIsForceUnlock(e.currentTarget.checked);
  };

  const handleExtendHoursChange = (e: any) => {
    if (isNaN(Number(e.currentTarget.value))) {
      return;
    }
    setExtendHours(e.currentTarget.value);
  };

  // Handle workshop deletion
  const handleDelete = async ({
    ignoreDate,
    dbOnly,
    force,
  }: {
    ignoreDate: boolean;
    dbOnly: boolean;
    force: boolean;
  }) => {
    if (workshop?.scheduledDate && isWithin48Hours(new Date(workshop.scheduledDate)) && !ignoreDate) {
      if (!showExtraConfirmModal) {
        setShowExtraConfirmModal(true);
        setShowDeleteModal(false);
      } else {
        history.push(prefixRoute(ROUTES.Environments));
      }
    } else {
      setShowDeleteModal(false);
      setShowExtraConfirmModal(false);

      try {
        await deleteWorkshop({
          id: id,
          ignoreDate: true,
          dbOnly: dbOnly ? 'true' : 'false',
          force: force ? 'true' : 'false',
        });
        // This will automatically create a Toast alert on success or failure
        history.push(prefixRoute(ROUTES.Environments));
      } catch (error) {
        // If the delete failed, don't navigate away from this page
        console.error('Error deleting workshop:', error);
      }
    }
  };

  const contextMenu = (
    <Menu>
      <Menu.Item
        label="Run tests"
        onClick={() => setShowTestModal(true)}
        disabled={!(authenticatedUser && hasPermission(authenticatedUser, 'environment:test'))}
      />
      <Menu.Divider />
      <Menu.Item
        label="Abort current job"
        onClick={() => {
          setShowCancelJobModal(true);
        }}
        disabled={
          !(authenticatedUser && hasPermission(authenticatedUser, 'environment:abort-job')) ||
          workshop?.jobId === null ||
          workshop?.state === 'aborted'
        }
      />
      <Menu.Item
        label="Extend lifetime"
        onClick={() => setShowExtendModal(true)}
        disabled={!(authenticatedUser && hasPermission(authenticatedUser, 'environment:extend'))}
      />
      <Menu.Item
        label="Rebuild"
        onClick={() => setShowRebuildModal(true)}
        disabled={!(authenticatedUser && hasPermission(authenticatedUser, 'environment:rebuild'))}
      />
      <Menu.Divider />
      <Menu.Item
        label="Unlink"
        onClick={() => setShowUnlinkModal(true)}
        destructive
        disabled={!(authenticatedUser && hasPermission(authenticatedUser, 'environment:unlink'))}
      />
      <Menu.Item
        label="Force delete"
        onClick={() => {
          setShowForceDeleteModal(true);
        }}
        destructive
        disabled={!(authenticatedUser && hasPermission(authenticatedUser, 'environment:force-delete'))}
      />
    </Menu>
  );

  return (
    <PluginPage
      info={[]}
      pageNav={{
        text: workshop?.description || workshop?.name || '...',
        img: '/public/img/grafana_icon.svg',
        // hideFromBreadcrumbs: true,
      }}
      actions={
        <HorizontalGroup spacing="md">
          <ToolbarButtonRow>
            <ToolbarButton
              icon="envelope"
              type="button"
              variant="canvas"
              onClick={() => setShowEmailModal(true)}
              disabled={
                !(authenticatedUser && hasPermission(authenticatedUser, 'environment:email')) ||
                !(workshop?.state?.toLowerCase() === 'provisioned')
              } // Disable the button if workshop state is not provisioned
              tooltip="Emails login details to each user"
            >
              Send user logins
            </ToolbarButton>
            <ToolbarButton
              icon="trash-alt"
              variant="destructive"
              type="button"
              onClick={() => setShowDeleteModal(true)}
              disabled={
                !(authenticatedUser && hasPermission(authenticatedUser, 'environment:delete')) ||
                workshop?.state?.toLowerCase() === 'provisioning'
              } // Disable the button if workshop state is provisioning
              tooltip="Delete workshop and all infrastructure"
            >
              Delete
            </ToolbarButton>
            <Dropdown overlay={contextMenu} placement="bottom-end">
              <IconButton
                name="ellipsis-v"
                aria-label="More actions"
                size="xl"
                variant="secondary"
                onClick={() => {}}
              />
            </Dropdown>
          </ToolbarButtonRow>
        </HorizontalGroup>
      }
    >
      {isLoading ? (
        <LoadingPlaceholder text="Loading workshop details..." />
      ) : (
        <div>
          {workshop ? (
            <Stack direction={'column'} gap={2}>
              <Stack direction={'row'} gap={3}>
                <EnvironmentStatusBadge status={workshop?.state} />
                <Tooltip content={'Environment will be auto-deleted on this date'}>
                  <div className={styles.metaBarItem}>
                    <Icon name="trash-alt" />
                    Deletion date: {humanExpiryDate(workshop.deprovisionDate)}
                  </div>
                </Tooltip>
                <Tooltip content={'Environment short ID'}>
                  <div className={styles.metaBarItem}>ID: {workshop?.name}</div>
                </Tooltip>
                <Tooltip content={'Owner/Creator'}>
                  <div className={styles.metaBarItem}>
                    <Icon name="user" />
                    Owned by: {workshop.leadSEAttendee?.email}
                  </div>
                </Tooltip>
                <Tooltip content={'Environment type'}>
                  <div className={styles.metaBarItem}>
                    <Icon name="cube" />
                    {workshop?.type || 'unknown'}
                  </div>
                </Tooltip>
              </Stack>
              <TabsBar>
                <Tab
                  label="Users & accounts"
                  key="users"
                  icon="user"
                  counter={
                    (workshop.workshopAttendees?.length ?? 0) +
                    (workshop.breakoutSEAttendees?.length ?? 0) +
                    (workshop.leadSEAttendee ? 1 : 0)
                  }
                  active={activeTab === 'users'}
                  onChangeTab={() => setActiveTab('users')}
                />
                <Tab
                  label="Environment details"
                  key="details"
                  icon="document-info"
                  active={activeTab === 'details'}
                  onChangeTab={() => setActiveTab('details')}
                />
              </TabsBar>

              <TabContent className={styles.tabContent}>
                {activeTab === 'details' && (
                  <Stack direction="column">
                    {isDeployableDemo === true && <DeployableDemoHeader workshop={workshop} />}

                    <WorkshopDocumentation outputs={workshop.outputs} readme={workshop.readme} />
                  </Stack>
                )}

                {activeTab === 'users' && (
                  <Stack direction={'column'} gap={2}>
                    <Stack direction={'row'} justifyContent={'flex-end'}>
                      <Button
                        variant="secondary"
                        icon="clipboard-alt"
                        onClick={() =>
                          copyToClipboard(
                            workshop.workshopAttendees
                              ?.map((item) => `${item.email},${item.name},${item.password}`)
                              .join('\n'),
                            'text'
                          )
                        }
                      >
                        Copy non-staff logins to clipboard as CSV
                      </Button>
                    </Stack>
                    <div>
                    {isDeployableDemo === false && (
                      <WorkshopAttendeeDetails
                        data={[
                          ...(workshop.workshopAttendees ?? []),
                          ...(workshop.breakoutSEAttendees ?? []),
                          ...(workshop.leadSEAttendee ? [workshop.leadSEAttendee] : [])
                        ]}
                      />
                    )}
                    {isDeployableDemo === true && <DeployableDemoUserDetails workshop={workshop} />}
                    </div>

                  </Stack>
                )}
              </TabContent>

              <VerticalGroup spacing="md">
                <div>
                  <ConfirmModal
                    isOpen={showDeleteModal}
                    title="Delete Workshop"
                    body="Are you sure you want to delete this workshop?"
                    confirmText="Delete"
                    icon="exclamation-triangle"
                    onConfirm={() => {
                      handleDelete({ dbOnly: false, ignoreDate: false, force: false });
                    }}
                    onDismiss={() => {
                      setShowDeleteModal(false);
                    }}
                  />
                </div>
                <div>
                  <ConfirmModal
                    isOpen={showExtraConfirmModal}
                    title="Are you sure?"
                    body="This workshop is scheduled within the next 48 hours. Are you sure you want to delete it? There is no guarantee you will be able to reprovision it in time."
                    confirmText="Yes, delete it"
                    icon="exclamation-triangle"
                    onConfirm={() => {
                      handleDelete({ dbOnly: false, ignoreDate: true, force: false });
                    }}
                    onDismiss={() => {
                      setShowExtraConfirmModal(false);
                    }}
                  />
                </div>
                <div>
                  <Modal
                    isOpen={showRebuildModal}
                    title="Rebuild this environment?"
                    onDismiss={() => setShowRebuildModal(false)}
                  >
                    <>
                      <VerticalGroup spacing="md">
                        <div>
                          <p>
                            Are you sure you want to rebuild this environment? This action will check the state of the
                            environment, and attempt to recreate any missing or broken resources.
                          </p>
                          <p>This process may take a couple of hours to complete.</p>
                        </div>
                        {authenticatedUser && hasPermission(authenticatedUser, 'environment:force-unlock') && (
                          <Checkbox
                            value={isForceUnlock}
                            label={'Forcibly unlock Terraform state before building'}
                            onChange={handleForceUnlockChange}
                            description="Check this only if you are sure that another job is not currently running"
                          />
                        )}
                        <HorizontalGroup justify="flex-end">
                          <Button variant="secondary" onClick={() => setShowRebuildModal(false)}>
                            Cancel
                          </Button>
                          <Button
                            variant="destructive"
                            onClick={() => {
                              handleRebuild();
                            }}
                          >
                            Rebuild environment
                          </Button>
                        </HorizontalGroup>
                      </VerticalGroup>
                    </>
                  </Modal>
                </div>
                <div>
                  <ConfirmModal
                    isOpen={showUnlinkModal}
                    title="Do you want to unlink this workshop?"
                    body="This operation removes this workshop from this application, and disconnects it from any infrastructure which been created. This operation cannot be undone. (If you're in doubt, this probably isn't what you want to do.)"
                    confirmText="Unlink"
                    icon="exclamation-triangle"
                    onConfirm={() => {
                      handleUnlink();
                    }}
                    onDismiss={() => {
                      setShowUnlinkModal(false);
                    }}
                  />
                </div>
                <div>
                  <ConfirmModal
                    isOpen={showEmailModal}
                    title="Send login details to users?"
                    body="This operation will send an email to all users with their login details. Are you sure you want to proceed?"
                    confirmText="Send"
                    confirmVariant="primary"
                    icon="exclamation-triangle"
                    onConfirm={() => {
                      handleEmail();
                    }}
                    onDismiss={() => {
                      setShowEmailModal(false);
                    }}
                  />
                </div>
                <div>
                  <ConfirmModal
                    isOpen={showTestModal}
                    title="Test workshop?"
                    body="This operation will run end-to-end automated tests for each user in the workshop environment. Are you sure you want to proceed?"
                    confirmText={`Test all ${
                      (workshop.workshopAttendees?.length ?? 0) + (workshop.breakoutSEAttendees?.length ?? 0) + 1
                    } users`}
                    icon="exclamation-triangle"
                    onConfirm={() => {
                      handleTest();
                    }}
                    onDismiss={() => {
                      setShowTestModal(false);
                    }}
                  />
                </div>
                <div>
                  <ConfirmModal
                    isOpen={showCancelJobModal}
                    title="Abort current job?"
                    body="This operation will abort the current provisioning job and may result in a broken workshop. Are you sure you want to proceed?"
                    confirmText="Abort job"
                    icon="exclamation-triangle"
                    onConfirm={() => {
                      handleCancelJob();
                    }}
                    onDismiss={() => {
                      setShowCancelJobModal(false);
                    }}
                  />
                </div>
                <div>
                  <ConfirmModal
                    isOpen={showForceDeleteModal}
                    title="Force delete?"
                    body="This operation will force delete the workshop and all infrastructure. Are you sure you want to proceed?"
                    confirmText="Force delete"
                    icon="exclamation-triangle"
                    onConfirm={() => {
                      handleDelete({ dbOnly: false, ignoreDate: true, force: true });
                    }}
                    onDismiss={() => {
                      setShowForceDeleteModal(false);
                    }}
                  />
                </div>
                <div>
                  <Modal
                    isOpen={showExtendModal}
                    title="Extend environment lifetime?"
                    onDismiss={() => setShowExtendModal(false)}
                  >
                    <VerticalGroup spacing="md">
                      <div>
                        <p>Extend the lifetime of an environment so that it is not automatically deleted.</p>
                      </div>
                      <InlineField
                        label="Extend by (hours)"
                        invalid={isNaN(Number(extendHours))}
                        error={'Must be a number'}
                      >
                        <Input placeholder="24" value={extendHours} onChange={handleExtendHoursChange} />
                      </InlineField>
                      <HorizontalGroup justify="flex-end">
                        <Button variant="secondary" onClick={() => setShowExtendModal(false)}>
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          onClick={() => {
                            handleExtend();
                          }}
                        >
                          Extend lifetime
                        </Button>
                      </HorizontalGroup>
                    </VerticalGroup>
                  </Modal>
                </div>
              </VerticalGroup>
            </Stack>
          ) : (
            <Alert title="Workshop not found" severity="error" />
          )}
        </div>
      )}
    </PluginPage>
  );
};

const getStyles = (theme: GrafanaTheme2) => ({
  tabContent: css({
    marginTop: theme.spacing(2),
  }),

  metaBarItem: css({
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  }),

  prose: css({
    maxWidth: '80ch',
  })
});

export default WorkshopDetails;
